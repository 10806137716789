/*
   共通処理
*/

$(() => {
  // aタグ
  // $('a.disabled').removeAttr('href')
  $('a.disabled').on('click', () => {
    return false
  })

  // タブレットでのタッチ DateRangePicker編
  $(document).on('touchstart', e => {
    if ($(e.target).data('drp')) {
      // 日付コンポーネントに該当する場合、readonlyを付加する
      $(e.target).attr('readonly', 'readonly')
      // readonly属性が設定されると背景色がグレー色になるので白色にさせる
      $(e.target).addClass('bg-white')
    }
  })
  // フォーカスが外れたら設定を元に戻す
  $(document).on('focusout', e => {
    if ($(e.target).data('drp')) {
      // 日付コンポーネントに付加したreadonlyを消す。
      $(e.target).removeAttr('readonly')
      $(e.target).removeClass('bg-white')
    }
  })
  // タブレットでのタッチ datepicker編
  $(document).on('touchstart', e => {
    if ($(e.target).data('dp')) {
      // 日付コンポーネントに該当する場合、readonlyを付加する
      $(e.target).attr('readonly', 'readonly')
      // readonly属性が設定されると背景色がグレー色になるので白色にさせる
      $(e.target).addClass('bg-white')
    }
  })
  // フォーカスが外れたら設定を元に戻す
  $(document).on('focusout', e => {
    if ($(e.target).data('dp')) {
      // 日付コンポーネントに付加したreadonlyを消す。
      $(e.target).removeAttr('readonly')
      $(e.target).removeClass('bg-white')
    }
  })
  $(window).on('hashchange', () => {
    $.fn.smooth().run()
  })
  $.fn.smooth().run();

  // 文字サイズ検証用
  (setClass => {
    if (setClass) {
      $('body').addClass(setClass)
    }
  })($.fn.getParam('addBodyClass'))
  /*
  // ユーザーエージェント
  (uaParser => {

    const ua_array = []
    const ua = navigator.userAgent.toLowerCase()

    // ipad
    if (ua.indexOf('ipad') > -1) {
      ua_array.push('ipad')
    } else
    // iphone
    if (ua.indexOf('iphone') > -1) {
      ua_array.push('iphone')
    } else
    // android
    if (ua.indexOf('android') > -1) {
      ua_array.push('android')
    }

    // ios PC表示
    if (ua.indexOf('macintosh') > -1 && 'ontouchend' in document) {
      ua_array.push('ios')
    } else

    // タッチ出来る端末
    if ('ontouchend' in document) {
      ua_array.push('touch')
    }

    // ブラウザ
    if (uaParser.getBrowser()) {
      if (uaParser.getBrowser().name) {
        ua_array.push(uaParser.getBrowser().name.toLowerCase())
      }
    }

    // OS
    if (uaParser.getOS()) {
      if (uaParser.getOS().name) {
        ua_array.push(uaParser.getOS().name.toLowerCase())
      }
    }

    // 重複削除
    ua_array.filter((x, i, self) => {
      return self.indexOf(x) === i
    })

    $.each(ua_array, (ua_index, ua_value) => {
      $('html').addClass(ua_value)
    })

  // eslint-disable-next-line no-undef
  })(new UAParser())
  */
})
